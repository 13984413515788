import { useAtomValue } from "jotai";
import { type FunctionComponent, useEffect, useMemo } from "react";

import { defaultAdditionalMetaTags, PageSeo } from "scmp-app/components/meta";
import { trackingAtom } from "scmp-app/components/tracking";
import { Edition, editionMetaDescription } from "scmp-app/lib/edition";
import { useCanonicalUrl } from "scmp-app/lib/hooks";
import { getRssFeedLink } from "scmp-app/lib/utils";

type Props = {
  edition: Edition;
};

export const HomeSeo: FunctionComponent<Props> = ({ edition }) => {
  const rssFeedLink = getRssFeedLink();

  const editionTitle = useMemo(() => {
    switch (edition) {
      case Edition.Asia:
        return "Breaking Asia, China, HK News, Opinions and Insights";
      case Edition.International:
        return "Breaking China, Asia, HK News, Opinions and Insights";
      default:
        return "Breaking Hong Kong, China, and World News, Opinions and Analysis";
    }
  }, [edition]);

  const editionDescription = editionMetaDescription[edition];

  const { canonicalUrl } = useCanonicalUrl(`/${edition === Edition.International ? "" : edition}`);

  const { trackPageView } = useAtomValue(trackingAtom);
  useEffect(() => {
    trackPageView?.({
      contentType: "Homepage",
      page: "Home",
      path: location.pathname,
    });
  }, [trackPageView]);

  return (
    <PageSeo
      additionalLinkTags={[rssFeedLink]}
      additionalMetaTags={[
        ...defaultAdditionalMetaTags,
        {
          content: "6860eccecbe3d7c71bd25fe2e28701b3",
          name: "p:domain_verify",
        },
      ]}
      canonical={canonicalUrl}
      description={editionDescription}
      languageAlternates={[
        {
          href: "https://www.scmp.com/hk",
          hrefLang: "en-hk",
        },
        {
          href: "https://www.scmp.com/asia",
          hrefLang: "en",
        },
        {
          href: "https://www.scmp.com",
          hrefLang: "x-default",
        },
      ]}
      title={editionTitle}
      twitter={{
        cardType: "summary",
      }}
    />
  );
};

HomeSeo.displayName = "HomeSeo";
